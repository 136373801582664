import { memo, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import FooterItem, { ItemProps } from './FooterItem'

function FooterCompany() {
  const trans = useTrans()
  const links = useMemo<Array<ItemProps>>(() => {
    return [
      { name: trans.footer.privacy_policy, url: '/privacy-policy' },
      { name: trans.footer.company_terms, url: '/terms-and-conditions' },
    ]
  }, [trans])
  return (
    <Stack data-testid="company-links-wrapper" direction="column" spacing={3}>
      <Typography
        data-testid="footer-company-links"
        variant="h1"
        className="text-sm font-bold not-italic text-iw-charcoal-black"
      >
        {trans.footer.company_others}
      </Typography>
      <Stack gap={2}>
        {links.map((item, index) => {
          return <FooterItem key={index} {...item} />
        })}
      </Stack>
    </Stack>
  )
}

export default memo(FooterCompany)
