import { memo } from 'react'
import { Stack, Link } from '@mui/material'
import { staticImportIcon } from '@/root/src/utils/static-import-icon'
import Image from 'next/image'

export interface ItemProps {
  name: string
  url: string
  icon?: string
  onlyIcon?: boolean
}

const FooterItem = (props: ItemProps) => {
  const { name, url, icon, onlyIcon } = props
  return (
    <Link
      data-testid="social-link-discord"
      href={url}
      target="_blank"
      rel="noreferrer"
      className="text-sm no-underline text-iw-charcoal-black hover:text-iw-secondary"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {icon && <Image src={staticImportIcon(icon)} alt={icon} width={32} />}
        {icon && onlyIcon ? '' : name}
      </Stack>
    </Link>
  )
}

export default memo(FooterItem)
