import { memo, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import FooterItem, { ItemProps } from './FooterItem'

function FooterMainMenu() {
  const trans = useTrans()
  const socials = useMemo<Array<ItemProps>>(() => {
    return [
      { name: trans.footer.main_menu_home, url: '/' },
      { name: trans.footer.main_menu_exclusive_deals, url: 'referral' },
      { name: trans.footer.main_menu_wire_race, url: '/wire-race' },
    ]
  }, [trans])
  return (
    <Stack data-testid="main-menu-links-wrapper" direction="column" spacing={3}>
      <Typography
        data-testid="footer-main-menu"
        variant="h1"
        className="text-sm font-bold not-italic text-iw-charcoal-black"
      >
        {trans.footer.main_menu}
      </Typography>
      <Stack gap={2}>
        {socials.map((item, index) => {
          return <FooterItem key={index} {...item} />
        })}
      </Stack>
    </Stack>
  )
}

export default memo(FooterMainMenu)
