import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

export const CustomExpandedBox = ({ children }: HocProps) => {
  return (
    <Box
      className="p-2 w-10 h-10 bg-iw-primary-light rounded-full my-4 sm:my-2"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Box>
  )
}

export const CustomExpandOpen = () => (
  <CustomExpandedBox>
    <AddIcon color="primary" fontSize="small" />
  </CustomExpandedBox>
)

export const CustomExpandClose = () => (
  <CustomExpandedBox>
    <CloseIcon color="primary" fontSize="small" />
  </CustomExpandedBox>
)
