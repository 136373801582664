import React from 'react'
import { Stack, Typography, styled } from '@mui/material'
import { CustomExpandOpen, CustomExpandClose } from '@/components/shared/expanded'

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}))

interface CustomAccordionProps {
  item: { question: string; answer: string }
  index: number
  expanded: string | false
  handleChange: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void
}

function CustomAccordion(props: CustomAccordionProps) {
  const { item, index, expanded, handleChange } = props
  return (
    <Accordion
      key={index}
      className="mb-6 rounded-xl"
      expanded={expanded === `panel_${index}`}
      onChange={handleChange(`panel_${index}`)}
    >
      <AccordionSummary
        aria-controls={`panel${index}d-content`}
        id={`panel${index}d-header`}
        className="gap-4 py-2 px-4 sm:px-14 items-start rounded-2xl"
        expandIcon={
          expanded !== `panel_${index}` ? (
            <CustomExpandOpen />
          ) : (
            <CustomExpandClose />
          )
        }
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'start', sm: 'center' }}
          gap={{ xs: 0, sm: 3 }}
        >
          <Typography className="font-ma font-bold text-[2rem] text-iw-primary">
            {String(index + 1).padStart(2, '0')}
          </Typography>
          <Typography className="font-semibold text-iw-charcoal-black">{item.question}</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails className="px-4 sm:px-28">
        <Typography
          className="text-iw-dark-gray"
          dangerouslySetInnerHTML={{
            __html: item.answer,
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
