import { memo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import InstantwireLogo from '@/components/base/InstantwireLogo'

function FooterInstantwire() {
  const trans = useTrans()
  return (
    <Stack data-testid="social-links-wrapper" direction="column" spacing={1}>
      <InstantwireLogo />

      <Typography
        data-testid="footer-title"
        className="font-semibold font-ma text-xl not-italic text-iw-neutral-black pr-0 md:pr-5 mt-5"
      >
        {trans.footer.title}
      </Typography>
      <Typography
        data-testid="footer-description"
        className="text-sm not-italic text-iw-dark-gray pr-0 md:pr-5"
      >
        {trans.footer.description}
      </Typography>
    </Stack>
  )
}

export default memo(FooterInstantwire)
