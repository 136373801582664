import React, { useCallback, useRef } from 'react'
import { IconButton, Stack } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

import Image from 'next/image'

// Swiper
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Pagination, Navigation } from 'swiper/modules'

function CardCarousel({
  id,
  items,
  xs,
  sm,
  md,
}: {
  id: string
  items: any[]
  xs: number
  sm: number
  md: number
}) {
  const swiperRef = useRef<SwiperRef>(null)

  const handlePrev = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) swiperRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) swiperRef.current.swiper.slideNext()
  }, [])

  return (
    <Stack className="w-full" alignItems="center" gap={3}>
      <Swiper
        ref={swiperRef}
        wrapperClass="box-border"
        slidesPerView={1}
        spaceBetween={20}
        pagination={{
          clickable: true,
          el: `.${id}-swiper-pagination`,
        }}
        breakpoints={{
          640: {
            slidesPerView: xs,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: sm,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: md,
            spaceBetween: 50,
          },
        }}
        className={`${id}-swiper box-border w-full`}
        modules={[Pagination, Navigation]}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>

      <Stack
        direction="row"
        className="bg-white px-5 py-3 rounded-full"
        alignItems="center"
        gap={1}
      >
        <IconButton className="bg-iw-primary-light p-[0.625rem]" onClick={handlePrev}>
          <Image
            alt={IconName.ARROW_CAROUSEL_LEFT}
            width={28}
            height={28}
            src={staticImportIcon(IconName.ARROW_CAROUSEL_LEFT)}
          />
        </IconButton>
        <Stack direction="row" className={`${id}-swiper-pagination`} />
        <IconButton className="bg-iw-primary-light p-[0.625rem]" onClick={handleNext}>
          <Image
            alt={IconName.ARROW_RIGHT}
            width={28}
            height={28}
            src={staticImportIcon(IconName.ARROW_CAROUSEL_RIGHT)}
          />
        </IconButton>
      </Stack>
    </Stack>
  )
}

export default CardCarousel
