import { IconName } from '@/utils/static-import-icon'

export const socials = (onlyIcon?: boolean) => [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/instantwire',
    icon: IconName.FACEBOOK_WHITE_ICON,
    onlyIcon: onlyIcon,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/InstantWireInfo',
    icon: IconName.X_WHITE ,
    onlyIcon: onlyIcon,
  },
  {
    name: 'Telegram',
    url: 'https://t.me/InstantwireCommunity',
    icon: IconName.TELE_WHITE_ICON,
    onlyIcon: onlyIcon,
  },
]
