import { Variants, motion, useAnimationControls, useScroll } from 'framer-motion'
import { useCallback, useEffect } from 'react'

import ArrowUp from '@/assets/svgs/arrow-up.svg'
import Image from 'next/image'

const ScrollToTopContainerVariants: Variants = {
  hide: { opacity: 0, y: 100 },
  show: { opacity: 1, y: 0 },
}

const isBrowser = () => typeof window !== 'undefined'

const ScrollToTopButton = () => {
  const { scrollYProgress } = useScroll()
  const controls = useAnimationControls()

  useEffect(() => {
    return scrollYProgress.on('change', (latestValue) => {
      if (latestValue > 0.25) {
        controls.start('show')
      } else {
        controls.start('hide')
      }
    })
  })

  const scrollToTop = useCallback(() => {
    if (!isBrowser()) return
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <motion.button
      className="fixed bottom-5 right-5 p-[0.725rem] w-12 h-12 rounded-full bg-iw-secondary z-[9999]"
      variants={ScrollToTopContainerVariants}
      initial="hide"
      animate={controls}
      onClick={scrollToTop}
    >
      <Image src={ArrowUp} alt="scroll to top" height={24} />
    </motion.button>
  )
}

export default ScrollToTopButton
