import React from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

import TransferMoneyImage from '@/assets/transfer-image.png'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'

function SendMoneyNow() {
  const trans = useTrans()
  return (
    <Box id="send-money-now" className="bg-white text-white px-4 py-10 sm:px-0 sm:py-20">
      <Container className="relative bg-iw-gradient-primary flex flex-col gap-4 rounded-2xl">
        <Grid container spacing={2} className="py-10">
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            className="flex flex-col items-start justify-center gap-3 sm:px-20"
          >
            <Typography className="font-bold text-[1.75rem] sm:text-4xl">
              {trans.pre_footer.title}
            </Typography>
            <Typography>{trans.pre_footer.description}</Typography>
            <Button
              className="bg-white border-none hover:text-iw-secondary font-bold flex items-center gap-3 px-10 mt-4 w-full sm:w-fit"
              component="a"
              href="/transfer-init"
            >
              <Image
                alt={IconName.INSTANTWIRE_BLUE_ICON}
                width={20}
                height={20}
                src={staticImportIcon(IconName.INSTANTWIRE_BLUE_ICON)}
              />
              {trans.pre_footer.send_money_now}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={5} className="flex items-center justify-center">
            <Image
              src={TransferMoneyImage}
              alt="transfer-money-image"
              className="w-[228px] sm:w-[310px]"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default SendMoneyNow
