import React, { memo } from 'react'
import { Container, Stack, Typography, Box, useTheme, Grid, Link } from '@mui/material'
import FooterInstantwire from '../components/FooterInstantwire'
import FooterCompany from '../components/FooterCompany'
import FooterMainMenu from '../components/FooterMainMenu'
import FooterSocial from '../components/FooterSocial'

const Footer = () => {
  const theme = useTheme()

  return (
    <Box className="bg-white">
      <Container className="mt-4">
        <Grid container className="my-14" spacing={{ xs: 4, sm: 1 }}>
          <Grid item xs={12} sm={4} md={5}>
            <FooterInstantwire />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <FooterMainMenu />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <FooterCompany />
          </Grid>
          <Grid item xs={12} sm={2} md={3}>
            <FooterSocial />
          </Grid>
        </Grid>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          className="py-8"
          sx={{
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Link href="https://renec.org" target="_blank" rel='noopener' className="no-underline">
            <Typography className="text-sm text-iw-dark-gray">
              © 2024 Renec Foundation UAB, Lithuania. All rights reserved.
            </Typography>
          </Link>
        </Stack>
      </Container>
    </Box>
  )
}

export default memo(Footer)
